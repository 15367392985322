var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"asset-card"},[_c('div',{staticClass:"asset-card__item",on:{"mouseover":function($event){_vm.assetHover = true},"mouseleave":function($event){_vm.assetHover = false}}},[_c('div',{staticClass:"asset-card__item-wrap"},[_c('router-link',{attrs:{"to":{
          name: _vm.ASSET_DETAIL_PAGE_NAME,
          params: { id: _vm.asset.id },
        }}},[_c('img',{staticClass:"asset-card__preview-image",attrs:{"src":_vm.getAssetThumbnail(_vm.asset),"loading":"lazy","width":"300","height":"150","alt":"Asset Thumbnail"}})]),(_vm.isNew())?_c('div',{staticClass:"asset-card__new-icon"},[_c('NewIcon'),_c('span',{staticClass:"asset-card__new-icon--text"},[_vm._v("NEW")])],1):_vm._e(),_c('div',{staticClass:"asset-card__top-row"},[_c('div',{staticClass:"asset-card__tags"},[(_vm.asset.blocked === true)?_c('span',{staticClass:"is-blocked"},[_vm._v("blocked")]):_vm._e(),(_vm.asset.hidden === true)?_c('span',{staticClass:"is-hidden"},[_vm._v("hidden")]):_vm._e()]),(
            _vm.loggedIn && _vm.belongsToLoggedInUser && _vm.asset.published !== undefined
          )?_c('div',{staticClass:"asset-card__published",attrs:{"title":_vm.asset.published ? 'Published' : 'Unpublished'}},[_c('i',{staticClass:"icon-tick-rounded asset-card__published-icon",class:{
              'icon-tick-rounded': _vm.asset.published,
              'icon-info-rounded': !_vm.asset.published,
            }},[(_vm.asset.published)?_c('PublishedThumbIcon',{attrs:{"width":"19","height":"15"}}):_c('UnpublishedThumbIcon',{attrs:{"width":"19","height":"15"}})],1)]):_vm._e(),(_vm.loggedIn && _vm.userIsAdmin)?_c('div',{staticClass:"asset-card__content-reviewed",attrs:{"title":_vm.userReviewedTitle(_vm.asset.user_content_reviewed)}},[(_vm.asset.user_content_reviewed === 'approved')?_c('ContentReviewApprovedIcon'):(_vm.asset.user_content_reviewed === 'pending_update')?_c('ContentReviewPendingUpdateIcon'):(_vm.asset.user_content_reviewed === 'pending')?_c('ContentReviewPendingIcon'):(_vm.asset.user_content_reviewed === 'rejected')?_c('ContentReviewRejectedIcon'):(_vm.asset.user_content_reviewed === 'no_content')?_c('ContentReviewNoContentIcon'):_vm._e()],1):_vm._e(),(
            _vm.loggedIn &&
            !_vm.belongsToLoggedInUser &&
            this.$route.name != _vm.HOME_PAGE_NAME_SLIDER
          )?_c('div',[_c('FavoriteWidget',{staticClass:"button asset-card__caption-subscribe",attrs:{"subscribed":_vm.asset.subscribed,"svg-width":19,"svg-height":15,"svg-fill":'pink',"with-text":false},on:{"toggle-favorite":_vm.toggleSubscription}})],1):_vm._e()]),_c('div',{staticClass:"asset-card__caption"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.assetHover)?_c('div',{staticClass:"asset-card__caption--name"},[_c('ul',{staticClass:"asset-card__list"},[(_vm.belongsToLoggedInUser)?_c('li',{staticClass:"asset-card__author",attrs:{"title":"'You'"}},[_vm._v(" You ")]):_c('li',{attrs:{"title":'Author: ' + _vm.getDeveloperName()}},[_c('router-link',{staticClass:"asset-card__author",attrs:{"to":{
                    name: _vm.AUTHOR_PROFILE_PAGE_NAME,
                    params: { id: _vm.asset.username },
                  }}},[_vm._v(" "+_vm._s(_vm.getDeveloperName())+" ")]),(_vm.asset.verified_author)?_c('div',{staticClass:"asset-card_verified-stamp"},[_c('span',{attrs:{"title":"Verified author"}},[_c('VerifiedBadgeIcon',{staticClass:"icon-verified"})],1)]):_vm._e()],1),(_vm.asset.released_at)?_c('li',{staticClass:"asset-card__date",attrs:{"title":'Released ' +
                  _vm.getFullDatetimeFormatted(new Date(_vm.asset.released_at))}},[_c('time',{attrs:{"datetime":_vm.asset.released_at}},[_vm._v(" "+_vm._s(_vm.getHumanReadableDiffString(new Date(_vm.asset.released_at)))+" ")])]):_vm._e()])]):_vm._e()])],1),_c('div',{staticClass:"asset-card__tag"},[(_vm.asset.tags)?_c('div',{staticClass:"find-tags-wrap find-tags-wrap--small"},[(_vm.asset.tags)?_c('ItemsVisualizer',{attrs:{"default-items":_vm.asset.tags,"removable":false,"clickable":true,"single":true}}):_vm._e()],1):_vm._e()])],1)]),_c('div',{staticClass:"asset-card__bottom-row"},[_c('router-link',{attrs:{"to":{
        name: _vm.ASSET_DETAIL_PAGE_NAME,
        params: { id: _vm.asset.id },
      }}},[_c('span',{staticClass:"asset-card__title",attrs:{"title":_vm.asset.name}},[_vm._v(_vm._s(_vm.shortenName(_vm.asset.name, 25)))])]),_c('RatingAndSubscribers',{attrs:{"rating":_vm.asset.rating,"reviews":_vm.asset.reviews,"subscribers":_vm.asset.subscribers_count,"readonly":true,"with-text":false,"hard-yellow":true}})],1),_c('YesModal',{ref:"yesNoDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }