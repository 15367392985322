<template>
  <article class="asset-card">
    <div
      class="asset-card__item"
      @mouseover="assetHover = true"
      @mouseleave="assetHover = false"
    >
      <div class="asset-card__item-wrap">
        <router-link
          :to="{
            name: ASSET_DETAIL_PAGE_NAME,
            params: { id: asset.id },
          }"
        >
          <img
            :src="getAssetThumbnail(asset)"
            loading="lazy"
            width="300"
            height="150"
            alt="Asset Thumbnail"
            class="asset-card__preview-image"
          />
        </router-link>

        <div v-if="isNew()" class="asset-card__new-icon">
          <NewIcon /><span class="asset-card__new-icon--text">NEW</span>
        </div>
        <div class="asset-card__top-row">
          <div class="asset-card__tags">
            <span v-if="asset.blocked === true" class="is-blocked"
              >blocked</span
            >
            <span v-if="asset.hidden === true" class="is-hidden">hidden</span>
          </div>
          <div
            v-if="
              loggedIn && belongsToLoggedInUser && asset.published !== undefined
            "
            class="asset-card__published"
            :title="asset.published ? 'Published' : 'Unpublished'"
          >
            <i
              class="icon-tick-rounded asset-card__published-icon"
              :class="{
                'icon-tick-rounded': asset.published,
                'icon-info-rounded': !asset.published,
              }"
            >
              <PublishedThumbIcon
                v-if="asset.published"
                width="19"
                height="15"
              />
              <UnpublishedThumbIcon v-else width="19" height="15" />
            </i>
          </div>
          <div
            v-if="loggedIn && userIsAdmin"
            class="asset-card__content-reviewed"
            :title="userReviewedTitle(asset.user_content_reviewed)"
          >
            <ContentReviewApprovedIcon
              v-if="asset.user_content_reviewed === 'approved'"
            />
            <ContentReviewPendingUpdateIcon
              v-else-if="asset.user_content_reviewed === 'pending_update'"
            />
            <ContentReviewPendingIcon
              v-else-if="asset.user_content_reviewed === 'pending'"
            />
            <ContentReviewRejectedIcon
              v-else-if="asset.user_content_reviewed === 'rejected'"
            />
            <ContentReviewNoContentIcon
              v-else-if="asset.user_content_reviewed === 'no_content'"
            />
          </div>
          <div
            v-if="
              loggedIn &&
              !belongsToLoggedInUser &&
              this.$route.name != HOME_PAGE_NAME_SLIDER
            "
          >
            <FavoriteWidget
              :subscribed="asset.subscribed"
              :svg-width="19"
              :svg-height="15"
              :svg-fill="'pink'"
              :with-text="false"
              class="button asset-card__caption-subscribe"
              @toggle-favorite="toggleSubscription"
            />
          </div>
        </div>
        <div class="asset-card__caption">
          <transition name="fade">
            <div v-if="assetHover" class="asset-card__caption--name">
              <ul class="asset-card__list">
                <li
                  v-if="belongsToLoggedInUser"
                  title="'You'"
                  class="asset-card__author"
                >
                  You
                </li>
                <li v-else :title="'Author: ' + getDeveloperName()">
                  <router-link
                    class="asset-card__author"
                    :to="{
                      name: AUTHOR_PROFILE_PAGE_NAME,
                      params: { id: asset.username },
                    }"
                  >
                    {{ getDeveloperName() }}
                  </router-link>
                  <div
                    v-if="asset.verified_author"
                    class="asset-card_verified-stamp"
                  >
                    <span title="Verified author">
                      <VerifiedBadgeIcon class="icon-verified" />
                    </span>
                  </div>
                </li>
                <li
                  v-if="asset.released_at"
                  class="asset-card__date"
                  :title="
                    'Released ' +
                    getFullDatetimeFormatted(new Date(asset.released_at))
                  "
                >
                  <time :datetime="asset.released_at">
                    {{
                      getHumanReadableDiffString(new Date(asset.released_at))
                    }}
                  </time>
                </li>
              </ul>
            </div>
          </transition>
        </div>
        <div class="asset-card__tag">
          <div v-if="asset.tags" class="find-tags-wrap find-tags-wrap--small">
            <ItemsVisualizer
              v-if="asset.tags"
              :default-items="asset.tags"
              :removable="false"
              :clickable="true"
              :single="true"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="asset-card__bottom-row">
      <router-link
        :to="{
          name: ASSET_DETAIL_PAGE_NAME,
          params: { id: asset.id },
        }"
      >
        <span :title="asset.name" class="asset-card__title">{{
          shortenName(asset.name, 25)
        }}</span>
      </router-link>

      <RatingAndSubscribers
        :rating="asset.rating"
        :reviews="asset.reviews"
        :subscribers="asset.subscribers_count"
        :readonly="true"
        :with-text="false"
        :hard-yellow="true"
      />
    </div>

    <YesModal ref="yesNoDialog" />
  </article>
</template>

<script>
import EventBus from '@/EventBus';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { addSuccesNotification } from '../../lib/notifications';
import YesModal from '../common/modal/ConfirmationDialog.vue';
import RatingAndSubscribers from '../common/RatingAndSubscribers.vue';
import ItemsVisualizer from '../common/ItemsVisualizer';
import FavoriteWidget from '@/components/common/FavoriteWidget.vue';

//JS
import DateTimeUtil from './../../lib/dateTimeUtil';
import shortenName from '@/lib/shortenName';

//SVGS
import VerifiedBadgeIcon from 'Sprites/verified_badge.svg?inline';
import PublishedThumbIcon from 'Sprites/published.svg?inline';
import UnpublishedThumbIcon from 'Sprites/unpublished.svg?inline';
import NewIcon from 'Sprites/new.svg?inline';
import ContentReviewApprovedIcon from 'Sprites/content_review-approved.svg?inline';
import ContentReviewPendingUpdateIcon from 'Sprites/content_review-pending_update.svg?inline';
import ContentReviewPendingIcon from 'Sprites/content_review-pending.svg?inline';
import ContentReviewRejectedIcon from 'Sprites/content_review-rejected.svg?inline';
import ContentReviewNoContentIcon from 'Sprites/content_review-no_content.svg?inline';

import {
  ASSET_DETAIL_PAGE_NAME,
  AUTHOR_PROFILE_PAGE_NAME,
  HOME_PAGE_NAME_SLIDER,
} from './../../router/pageNameConstants';
import { AssetsResource } from './../../services/assets';

let resizeDebounceTimer = null;

export default {
  components: {
    FavoriteWidget,
    RatingAndSubscribers,
    YesModal,
    ItemsVisualizer,
    VerifiedBadgeIcon,
    PublishedThumbIcon,
    UnpublishedThumbIcon,
    NewIcon,
    ContentReviewApprovedIcon,
    ContentReviewPendingUpdateIcon,
    ContentReviewPendingIcon,
    ContentReviewRejectedIcon,
    ContentReviewNoContentIcon,
  },
  props: {
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      previewWidth: null,
      ASSET_DETAIL_PAGE_NAME,
      AUTHOR_PROFILE_PAGE_NAME,
      HOME_PAGE_NAME_SLIDER,
      assetHover: false,
    };
  },
  computed: {
    ...mapGetters('user', {
      loggedIn: 'isLogged',
      userId: 'getUserId',
      user: 'getUser',
    }),
    ...mapGetters('settings', {
      previewSizes: 'previewSizes',
      thumbnailsUrl: 'thumbnailsUrl',
      webpSuppport: 'webp',
    }),
    getWebpTypeIfSupported() {
      if (this.webpSupports) {
        return '&type=webp';
      }
      return '&type=jpg';
    },
    belongsToLoggedInUser() {
      return this.asset.user_id === this.userId;
    },
    userIsAdmin() {
      return this.user.profile.isAdmin;
    },
  },

  mounted() {
    this.previewWidth = this.getPreviewWidth(this.$el.offsetWidth);
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    userReviewedTitle(review) {
      let state;
      switch (review) {
        case 'pending':
          state = 'Pending';
          break;
        case 'pending_update':
          state = 'Pending Update';
          break;
        case 'no_content':
          state = 'No Content';
          break;
        case 'approved':
          state = 'Approved';
          break;
        case 'rejected':
          state = 'Rejected';
          break;
        case null:
          state = 'Not processed';
          break;

        default:
          state = 'N/A';
          break;
      }
      return state;
    },
    toggleSubscription(subscribed) {
      this.$refs.yesNoDialog
        .init(subscribed ? 'Add to favorites?' : 'Remove from favorites?')
        .then((agree) => {
          if (agree) {
            return AssetsResource.subscribe(
              {
                id: this.asset.id,
              },
              {
                subscribed: subscribed,
              }
            ).then(() => {
              Vue.set(this.asset, 'subscribed', subscribed);
              addSuccesNotification(
                'Successfully ' +
                  (subscribed ? 'added to favorites' : 'unsubscribed')
              );
              if (!subscribed) {
                EventBus.$emit('AssetUnsubscribe', this.asset.id);
              }
            });
          }
        });
    },
    isNew() {
      return (
        new Date(
          Date.parse(this.asset.released_at) + 60 * 60 * 24 * 14 * 1000
        ) > new Date()
      );
    },
    getDeveloperName() {
      if (this.loggedIn && this.belongsToLoggedInUser) {
        return 'You';
      }

      return this.asset.ylands_username || this.asset.username;
    },

    getPreviewWidth(width) {
      if (this.previewSizes) {
        let previewWidth = this.previewSizes[0];
        for (let i = 1; i < this.previewSizes.length; i++) {
          if (previewWidth < width) {
            previewWidth = this.previewSizes[i];
          } else {
            break;
          }
        }
        return previewWidth;
      }
    },
    getAssetThumbnail(asset) {
      if (asset && asset.thumbnail && asset.thumbnail.file_id) {
        const type = this.webpSuppport ? 'webp' : 'jpg';

        return `/api/images/${asset.thumbnail.file_id}?width=${this.previewWidth}&type=${type}`;
      }

      if (this.webpSuppport) {
        return require('Images/asset_thumb_placeholder.webp');
      } else {
        return require('Images/asset_thumb_placeholder.jpg');
      }
    },

    handleResize() {
      let width = this.$el.offsetWidth;
      if (resizeDebounceTimer) {
        clearTimeout(resizeDebounceTimer);
      }
      resizeDebounceTimer = setTimeout(() => {
        this.previewWidth = this.getPreviewWidth(width);
      }, 500);
    },
    getHumanReadableDiffString: function (date) {
      return DateTimeUtil.getHumanReadableDiffString(date);
    },
    getFullDatetimeFormatted: function (date) {
      return DateTimeUtil.getFullDateTime(date);
    },
    shortenName,
  },
};
</script>
<style scoped lang="scss">
.blocked {
  cursor: not-allowed;
}

.asset-card {
  margin-bottom: 15px;

  &--lazy {
    .asset-card__preview-image {
      background-image: none !important;
    }
  }
}

.asset-card__preview-image {
  cursor: pointer;
  display: block;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 177px;
}

.asset-card__item {
  position: relative;
  background-color: #ccc;
  width: 100%;
  height: 177px;
  transition: box-shadow $transition-values;
  border-radius: 7px;

  @media (min-width: $md-up) {
    &:before {
      pointer-events: none;
      content: '';
      display: block;
      position: absolute;
      height: 0%;
      width: 100%;
      bottom: 0;
      transition: height $transition-values;
      z-index: 1;
      border-radius: 7px;
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        #000000 100%
      );
      opacity: 0.83;
    }
    &:hover:before {
      height: 5em;
    }
    &:hover {
      box-shadow: 2px 2px 5px #acacac;
    }
  }
}

.asset-card__item-wrap {
  overflow: hidden;
  position: relative;
  border-radius: 7px;
}

.asset-card__new-icon {
  position: absolute;
  top: 0;
  left: 1.1rem;
  width: rem(28);
  height: rem(55);

  &--text {
    position: absolute;
    left: 0;
    top: 25%;
    transform: rotate(-90deg);
    color: #ffffff;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.04px;
    text-transform: uppercase;
  }
}

.asset-card__caption {
  position: relative;
  display: flex;
  flex-direction: row;

  &--name {
    left: 1em;
    position: absolute;
    bottom: 0.75em;
    z-index: 2;

    .asset-card__list {
      @include listReset;
      font-size: rem(12);

      li {
        color: $fake-white;
        line-height: 1.4;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
      }
    }
  }
}

.asset-card__top-row {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  z-index: 2;

  .asset-card__tags {
    margin-top: 5px;
    height: 20px;
    z-index: 5;
    display: inline-flex;
    justify-items: center;
    align-items: center;

    .is-new {
      background-color: #70b5da;
      border-radius: 5px;
      padding: 4px;
      font-size: 0.8em;
      color: white;
    }

    .is-blocked {
      background-color: red;
      border-radius: 5px;
      padding: 4px;
      font-size: 0.8em;
      color: white;
    }
    .is-hidden {
      background-color: #cccccc;
      border-radius: 5px;
      padding: 4px;
      font-size: 0.8em;
      color: white;
    }
  }

  .asset-card__content-reviewed {
    svg {
      width: 31px;
      height: 31px;
      margin-right: 5px;
    }
  }

  .asset-card__caption-subscribe {
    line-height: 0;
    outline: 0;
    transition: $transition-values;

    width: 31px;
    height: 31px;
    border: 1px solid #ffffff;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 100%;
    vertical-align: middle;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.asset-card__tag {
  margin-top: 5px;
  height: 20px;
  position: absolute;
  right: 0;
  bottom: 0.75em;
  z-index: 5;
}

.tag-blocked {
  background-color: red;
}

.asset-card__badge {
  position: absolute;
  top: em(-5);
  right: 0.5em;

  svg {
    width: 100px;
    height: 32px;
  }
}
.asset-card__author {
  color: #ffffff;
  font-family: $istok-font;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.51px;
}

.asset-card__date {
  color: #ffffff;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.42px;
}

.asset-card__bottom-row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    min-width: 0;

    .asset-card__title {
      display: inline-block;
      position: relative;
      color: $blue-main;
      font-family: $istok-font;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 0.12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
}

.asset-card_verified-stamp {
  display: inline-flex;
  padding-left: 0.5em;
}

//TRANSITION
.fade-enter-active,
.fade-leave-active {
  transition: opacity $transition-values;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
