export default function shortenName(str, len) {
    if (str.length <= len) {
        return str;
    }
    str = str.slice(0, len);
    // crop white space at the end of the string
    if (str.charAt(str.length - 1) === ' ') {
        str = str.slice(0, len - 1);
    }
    return str + ' ...';
}
